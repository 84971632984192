<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="''">
          <template v-slot:title>
            {{ $t("classrooms.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="add">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("classrooms.common.building") }}
                    </th>
                    <th class="text-left">
                      {{ $t("classrooms.common.classroomNumber") }}
                    </th>
                    <th class="text-left">
                      {{ $t("classrooms.common.outOfService") }}
                    </th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in classrooms" :key="item.id">
                    <td>{{ item.building }}</td>
                    <td>{{ item.classroomNumber }}</td>
                    <td>{{ item.outOfService }}</td>
                    <td>
                      <b-button
                        type="submit"
                        @click="edit(item)"
                        variant="primary"
                      >
                        {{ $t("common.edit") }}
                      </b-button>

                      <b-button
                          v-if="item.isDeletable == true"
                          @click="deleteClassroom(item)"
                          variant="danger"
                      >
                        {{ $t("common.delete") }}
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <b-modal ref="classrooms-add-edit-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("classrooms.common.building") }}
                    </template>
                    <b-form-select
                        v-model="form.building"
                        :options="buildings"
                        :state="classroomModelStates.building"
                    ></b-form-select>
                    <div class="invalid-feedback">{{classroomModelSaveError.building}}</div>
                  </b-form-group>
                </b-form>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("classrooms.common.classroomNumber") }}
                    </template>
                    <b-form-input
                        v-model="form.classroomNumber"
                        :state="classroomModelStates.classroomNumber"
                        required
                    ></b-form-input>
                    <div class="invalid-feedback">{{classroomModelSaveError.classroomNumber}}</div>
                  </b-form-group>
                </b-form>
                <b-form>
                  <b-form-group>
                    <b-form-checkbox
                        v-model="classroomModelStates.outOfService"
                        :state="classroomModelStates.outOfService"
                    > {{ $t("classrooms.common.outOfService") }}
                    </b-form-checkbox>
                    
                    <div class="invalid-feedback">{{classroomModelSaveError.outOfService}}</div>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "teachers",
  components: {
    KTPortlet
  },
  data() {
    return {
      classrooms: [],
      dialog: true,
      form: {
        building: "",
        classroomNumber: "",
        outOfService: false
      },
      buildings: [1, 2, 3, 4, 5, 6],
      classroomModelStates:{},
      classroomModelSaveError:{},
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("classrooms/save", this.form)
        .then(function() {
          $this.$refs["classrooms-add-edit-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          if(response.data != null && response.data.errors != null){
            $this.classroomModelSaveError = response.data.errors;

            for (let i in response.data.errors){

              $this.classroomModelStates[i] = false;
            }
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["classrooms-add-edit-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("classrooms/list", {})
        .then(({ data }) => {
          this.classrooms = data.list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    cleanModel() {
      this.form = {};
    },
    add() {
      this.form = {};
      this.$refs["classrooms-add-edit-modal"].show();
    },
    edit(classroom) {
      this.form = classroom;
      this.$refs["classrooms-add-edit-modal"].show();
    },
    deleteClassroom(teacher) {
      let $this = this;
      if(confirm("Удалить кабинет?")){
        ApiService.postSecured("classrooms/delete", {id:teacher.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    }
  }
};
</script>
